@font-face {
    font-family: "Montserrat";
    src: url("./assets/font/Montserrat-Regular.ttf") format("truetype");
}

*:focus {
    outline:none !important
}

*{
    user-select: none;
}

html, body{
    width: 100%;
    height: 100%;
    font-family: "Montserrat";
}